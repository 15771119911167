const chars : any = {
  'é': 'e',
  'è': 'e',
  'ç': 'c',
  '-': ' ',
  'œ': 'oe',
  "'": " ",
  "à": "a",
  'ê': 'e'
};

export function SanitizeString(name: string) {
  return name.replace(/[éèçà\-œê']/g, c => chars[c]);
}
